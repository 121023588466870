.slide-exit {
  transform: translateX(100%);
}

.slide-exit-active {
  transform: translateX(0%);
}

.slide-enter-active,
.slide-exit-active {
  transition: all 300ms;
}
