* {
  padding: 0;
  margin: 0;
}
input {
  outline: none;
  border: none;
}
@font-face {
  font-family: myFont;
  src: url("./static/css/icon.ttf");
}
html,
body {
  touch-callout: none;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-user-select: none;
}
