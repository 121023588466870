.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  z-index: 999;
  font-size: 0.256rem;
  background: rgba(0, 0, 0, 0.6);
  padding: 0.16rem 0.32rem;
  border-radius: 0.1rem;
}
