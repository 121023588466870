@font-face {
  font-family: "iconfont"; /* Project id  */
  src: url('iconfont.ttf?t=1680860396715') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-dianzan:before {
  content: "\e601";
}

.icon-31yiwen:before {
  content: "\e600";
}

.icon-gouxuan:before {
  content: "\e639";
}

.icon-baocun:before {
  content: "\e62a";
}

.icon-iconfontgantanhao2:before {
  content: "\e608";
}

.icon-icon:before {
  content: "\e650";
}

.icon-dizhi:before {
  content: "\e641";
}

.icon-jinji:before {
  content: "\e62f";
}

.icon-kuaidi:before {
  content: "\e644";
}

.icon-duihao2:before {
  content: "\e69e";
}

.icon-xiangxiajiantou:before {
  content: "\e60c";
}

.icon-pinglun:before {
  content: "\e891";
}

.icon-fuzhi:before {
  content: "\e636";
}

.icon-zuojiantou:before {
  content: "\e67c";
}

.icon-huo:before {
  content: "\e607";
}

.icon--jianhao:before {
  content: "\e606";
}

.icon-guanbi:before {
  content: "\eb6a";
}

.icon-baoguo_hezi_o:before {
  content: "\ebb6";
}

.icon-sousuo:before {
  content: "\e620";
}

.icon-kefu:before {
  content: "\e699";
}

.icon-dianzan_kuai:before {
  content: "\ec8c";
}

.icon-youhuiquan1:before {
  content: "\e60b";
}

.icon-jiahao:before {
  content: "\e605";
}

.icon-24gl-heart:before {
  content: "\e899";
}

.icon-24gf-heart:before {
  content: "\e89a";
}

.icon-lajitong:before {
  content: "\e615";
}

.icon-youjiantou:before {
  content: "\e643";
}

.icon-xiangyou:before {
  content: "\e65a";
}

.icon-bijijilu:before {
  content: "\e6f3";
}

.icon-youjiantou2:before {
  content: "\e625";
}

.icon-youjiantou1:before {
  content: "\e64b";
}

.icon-bianji:before {
  content: "\e76d";
}

.icon-zhixiangjiantou-xianxing-you:before {
  content: "\e69b";
}

.icon-gouwuche:before {
  content: "\e602";
}

.icon-a-zhibo2beifen:before {
  content: "\e603";
}

.icon-a-zhuanfa12:before {
  content: "\e604";
}

.icon-dizhi1:before {
  content: "\e60a";
}

.icon-xiaoxi-zhihui:before {
  content: "\e60d";
}

.icon-fenxiang:before {
  content: "\e60e";
}

.icon-zan:before {
  content: "\e60f";
}

.icon-dianzan_kuai1:before {
  content: "\e610";
}

.icon-bianji1:before {
  content: "\e614";
}

.icon-quanju_tousu:before {
  content: "\e616";
}

.icon-kefudianhua:before {
  content: "\e617";
}

.icon-a-huabanbeifen6:before {
  content: "\e618";
}

.icon-a-huabanbeifen18:before {
  content: "\e619";
}

.icon-a-huabanbeifen17:before {
  content: "\e61b";
}

.icon-a-huabanbeifen8:before {
  content: "\e613";
}

.icon-a-huabanbeifen9:before {
  content: "\e61a";
}

